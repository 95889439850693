import React from 'react';

const Episode = ({ title, description, videoId }) => {
  const openInAppLink = `vnd.youtube://${videoId}`; // YouTube app deep link

  return (
    <div className="w-[100%] h-[500px]">
      {/* <h2>{title}</h2> */}
      {/* <p>{description}</p> */}
      <iframe
        title={title}
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <a href={openInAppLink}>Open in App</a>
    </div>
  );
};

export default Episode;
