import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA4873rQI-pTndHXYhU1vv6yCuhy-ouqAg",
    authDomain: "lewsr-newsletter.firebaseapp.com",
    projectId: "lewsr-newsletter",
    storageBucket: "lewsr-newsletter.appspot.com",
    messagingSenderId: "161837999531",
    appId: "1:161837999531:web:9551a9b5f061b66beb1eee"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);

export default db;
