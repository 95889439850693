import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const About = () => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
        y: 100,
      });
    }
  }, [inView, animation]);

  return (
    <div
      className="w-full xl:h-[75vh] py-28 text-left px-3 text-white bg-[#0A0A0A]"
      id="About"
    >
      <div className="max-w-[1240px] mx-auto ">
        <div className="font-Archivo lg:text-[5rem] text-4xl tracking-tight">
          <h1 className="tracking-tight">INFO</h1>
        </div>
        <motion.div
          ref={ref}
          animate={animation}
          className="grid lg:grid-cols-2 gap-8 xl:gap-12 justify-center xl:mt-[10vh] mt-96"
        >
          <div className="">
            <div className="font-Roboto">
              <p className="py-3">
                Lewsr, a native of the vibrant city of San Antonio, Texas, has
                carved a unique path as a dynamic podcaster. Hailing from the
                heart of the Lone Star State, Lewsr's appreciation for
                authenticity shines through his passion for street photography,
                where he captures the raw and unfiltered essence of everyday
                life. Beyond his skill with the lens, Lewsr boasts an unexpected
                talent – that of a master embroider, crafting intricate designs
                that mirror the creativity evident in his visual storytelling.
              </p>
              <p className="py-3">
                {" "}
                With an unwavering dedication to his craft, he is perpetually
                immersed in the world of photography, ceaselessly clicking his
                camera to freeze fleeting moments in time.
              </p>
              <p className="py-3">
                {" "}
                Not one to shy away from pursuing his passion professionally,
                Lewsr eagerly accepts photography assignments, a testament to
                his commitment and expertise. Beyond his lens, he finds solace
                in the art of travel, seeking inspiration and new horizons to
                fuel his creative pursuits.
              </p>
            </div>
            <motion.div
              ref={ref}
              animate={animation}
              className="grid lg:grid-cols-3 text-center gap-3 mt-8"
            >
              <div className="w-full">
                <a
                  href="https://www.youtube.com/@telewsico/videos"
                  target="_blank"
                  type="button"
                  className="text-white w-full bg-red-600 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  YouTube Channel
                </a>
              </div>
              <div className="w-full">
                <a
                  href="https://www.instagram.com/lewsr/"
                  target="_blank"
                  type="button"
                  className="text-white w-full bg-purple-600 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:bg-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                >
                  Instagram Account
                </a>
              </div>
              <div className="w-full">
                <a
                  href="https://open.spotify.com/show/0Ot6sBZH695vm9pyiWDCNn?si=XNhPoxVEQRe0aZDON8iUMA&nd=1"
                  type="button"
                  target="_blank"
                  className="text-white w-full bg-green-600 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5  me-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Spotify Podcast
                </a>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
