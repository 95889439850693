export const photos = [
  {
    src: "https://lewsr.com/photos/20.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/1.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/2.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/3.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/4.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/5.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/6.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/7.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/8.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/9.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/12.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/13.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/14.JPG",
    width: 4,
    height: 3,
  },

  {
    src: "https://lewsr.com/photos/19.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/21.JPG",
    width: 4,
    height: 3,
  },
  {
    src: "https://lewsr.com/photos/22.jpg",
    width: 1,
    height: 1,
  },
];
