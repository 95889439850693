import React from 'react'
import Marquee from "react-fast-marquee";

const Banner = () => {
    return (
        <Marquee gradient={false} pauseOnHover={true} speed={100} loop={0} className='w-full h-28 flex justify-center bg-[#A6FD5B] font-Archivo tracking-tighter uppercase text-4xl'>
         <p> Podcast Host  *  Graffiti  *  Photography  *  Te Lew Sico Podcast *  Artist *  Podcast Host  *  Graffiti  *  Photography  *  Te Lew Sico Podcast  *  Artist  *</p>
        </Marquee>
    )
}

export default Banner