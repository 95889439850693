import React from 'react'
import Typed from "react-typed"
import { motion } from 'framer-motion'


const Hero = () => {
    return (
        <div className='text-white' id='Hero'>
            <div className='flex flex-col justify-center mt-[-50px] w-full h-screen mx-auto px-3 sm:px-5 relative'>
                <Typed className='text-white font-Archivo lg:text-7xl md:text-7xl text-4xl mt-24 sm:mb-0 tracking-tighter '
                    strings={["I'M NOT A STREET PHOTOGRAPHER, I'M THE STREETS' PHOTOGRAPHER"]}
                    typeSpeed={8}
                />
                <motion.p 
                    animate={{y: 0, opacity: 1}} 
                    initial={{y: 120, opacity: 0}} 
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    className='font-Roboto lg:text-lg md:text-xl xl:px-56 sm:text-xl text-base md:py-6 pt-3'>
                       From asphalt tales to graffiti symphonies, this is Lewsr unchained – where every shot, every word, packs a gritty punch. Get ready for pulse-pounding tales from the asphalt arena, ’cause we’re here to drop truth bombs, one edgy story at a time.
                </motion.p>
            </div>
        </div>
    )
}

export default Hero
