import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BrowserRouter } from "react-router-dom";
import Logo from "../assets/lewsr-logo.png";
import { NavHashLink } from "react-router-hash-link";

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <BrowserRouter>
      <div
        id="Nav"
        className="flex font-Archivo fixed text-left mx-auto top-0 left-0 right-0 z-50 bg-neutral-0/80 py-3 backdrop-blur justify-between items-center h-28 px-3 lg:px-10 text-white"
      >
        <img className=" h-[60px]" src={Logo} alt="/" />
        <h1
          id=""
          className="w-full font-Archivo text-3xl text-white tracking-tighter uppercase"
        >
          Lewsr
        </h1>
        <ul className="hidden Roboto md:flex uppercase pr-4">
          <NavHashLink to="#About" smooth className="p-4 hover:text-[#A6FD5B]">
            INFO
          </NavHashLink>
          <NavHashLink
            to="#Podcast"
            smooth
            className="p-4 hover:text-[#A6FD5B] uppercase"
          >
            PODCAST
          </NavHashLink>
          <NavHashLink
            to="#photography"
            smooth
            className="p-4 hover:text-[#A6FD5B]"
          >
            Photography
          </NavHashLink>
          {/* <a href='https://www.shopify.com/' className='p-4 hover:text-[#A6FD5B]'>Shop</a> */}
          {/* <NavHashLink to='#Contact' smooth className='bg-[#000000] font-ibmBold font-bold text-center pt-4 py-3 w-[200px] text-white hover:bg-[#A6FD5B] hover:text-[#000000] transition ease-in duration-300'>CONTACT ME</NavHashLink> */}
        </ul>

        <div onClick={handleNav} className="block md:hidden z-50">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        <div
          className={
            nav
              ? "fixed left-0 top-0 w-full h-full border-r bg-[#221EEC] border-r-gray-900 ease-in-out duration-500"
              : "fixed left-[-100%] uppercase bg-[#221EEC]"
          }
        >
          <ul className="sm:pt-28 pt-28 sm:text-6xl text-5xl uppercase p-4 h-screen bg-[#000000]">
            <NavHashLink
              onClick={handleNav}
              to="#About"
              smooth
              className="flex p-8"
            >
              INFO
            </NavHashLink>
            <NavHashLink
              onClick={handleNav}
              to="#photography"
              smooth
              className="flex p-8 uppercase"
            >
              Photo
            </NavHashLink>
            <NavHashLink
              onClick={handleNav}
              to="#Podcast"
              smooth
              className="flex p-8"
            >
              PODCAST
            </NavHashLink>
            {/* <NavHashLink onClick={handleNav} to='#Contact' smooth className='flex p-8'>CONTACT</NavHashLink> */}
          </ul>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default NavBar;
