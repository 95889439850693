import './App.css';
import Hero from './components/Hero';
import NavBar from './components/NavBar';
import About from './components/About';
import Banner from './components/Banner';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Podcast from './components/Podcast'
import Newsletter from './components/Newsletter';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Hero />
      <About />
      <Newsletter />
      <Banner />
      <Podcast />
      <Gallery />
      {/* <Contact/> */}
      <Footer />
    </div>
  );
}

export default App;
