import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Episode from "./Episode";

const Projects = () => {
  const [videos, setVideos] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    // Define your API endpoint URL
    const apiUrl =
      "https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UC44JCvtou5ukQg6qU6mj3hg&maxResults=6&order=date&key=" +
      process.env.REACT_APP_API_KEY;

    // Fetch data from the API using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        const videoData = response.data.items.map((item) => ({
          title: item.snippet.title,
          description: item.snippet.description,
          videoId: item.id.videoId,
        }));
        setVideos(videoData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
        y: 100,
      });
    }
  }, [inView, animation]);

  return (
    <div className="w-full py-28 px-3 text-white bg-[#000000]" id="Podcast">
      <motion.div
        ref={ref}
        animate={animation}
        className="max-w-[1240px] mx-auto"
      >
        <div className="font-Archivo lg:text-[5rem] text-[3rem] tracking-tight">
          <h1 className="font-Archivo text-left uppercase mb-10">
            Te Lew Sico
          </h1>
        </div>
        <p className=" text-left mb-10">
          The "Te Lew Sico" podcast is a captivating and vibrant platform that
          celebrates the talents of emerging artists, musicians, thought
          leaders, and underground creatives. Hosted by the charismatic Lewsr,
          the podcast stands out with its distinctive style and engaging
          approach. With an eye for spotting rising stars and unconventional
          voices, the show provides a spotlight for individuals who are making
          waves in their respective fields but might not yet have received
          widespread recognition.
          <p />
          <p className=" text-left mb-10">
            Lewsr's unique hosting style creates an atmosphere that is both
            relaxed and invigorating, allowing guests to share their stories,
            insights, and perspectives in an authentic and unfiltered manner.
            From exploring the creative processes of musicians and artists to
            delving into the innovative ideas of thought leaders, "Te Lew Sico"
            offers listeners an opportunity to discover fresh talents and gain a
            deeper understanding of the diverse and dynamic world of creativity.
          </p>
          <p className=" text-left mb-10">
            Whether you're an avid supporter of the arts, an enthusiast of
            underground culture, or simply curious about the stories behind
            emerging talents, the "Te Lew Sico" podcast promises an enriching
            and entertaining experience that highlights the voices that are
            shaping the cultural landscape of tomorrow.
          </p>
        </p>
        <div className="grid lg:grid-cols-2 gap-8">
          {videos.map((video, index) => (
            <Episode
              key={index}
              title={video.title}
              description={video.description}
              videoId={video.videoId}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Projects;
