import React from 'react'
import Gallery from "react-photo-gallery";
import { photos } from "./Photos";




const Projects = () => {
    //     const { ref, inView } = useInView({
    //     threshold: 0.1
    // })
    // const animation = useAnimation()

    // useEffect(() => {
    //     if (inView) {
    //         animation.start({
    //             opacity: 1,
    //             y: 0,
    //             transition: {
    //                 duration: 0.5
    //             }
    //         })
    //     }
    //     if (!inView) {
    //         animation.start({
    //             opacity: 0,
    //             y: 100
    //         })
    //     }
    // }, [inView])

// Gallery Images


    return (
        <div className='w-full py-28 px-3 text-white bg-[#000000]' id='photography'>
            <div className='mx-auto max-w-[1240px]'>
                <div className='font-Archivo lg:text-[5rem] text-[3rem] tracking-tight'>
                    <h1 className='font-Archivo text-left uppercase mb-10'>Photos</h1>
                </div>
                <p className=' text-left mb-10'>Lewsr, is renowned street photographer hailing from San Antonio's Westside, has a distinctive and immersive approach to his craft. His process involves cruising through the diverse neighborhoods of his city, letting the atmosphere and essence of each area seep into his senses. As he drives along with his windows down, he absorbs the unique vibes of the streets, allowing the scents and sounds to intertwine with his creative vision. This connection to his surroundings fuels his passion for capturing the raw, authentic moments that unfold in the urban landscape. With an innate affinity for his Westside locale, Lewsr's work reflects his deep-rooted enjoyment of photographing the very heart of the streets he calls home.</p>
                <div className=''>
                <Gallery 
                    photos={photos} 
                    direction={"column"}
                    margin={5}
                />
                </div>
            </div>
        </div>
    )
}

export default Projects
