import React, { useState, useRef } from "react";
import db from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export default function Newsletter() {
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const downloadLinkRef = useRef(null);

  const magazineUrl = "https://lewsr.com/photos/Exclusive-Booklet.pdf";

  const inputHandler = (e) => {
    setInput(e.target.value);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!isValidEmail(input)) {
      setMessage("Please enter a valid email address.");
      setTimeout(() => setMessage(""), 3000);
      return;
    }

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, "email"), {
        email: input,
        time: serverTimestamp(),
      });
      setInput("");
      setMessage(
        "Thank you for subscribing! Your download will start shortly."
      );
      setDownloadUrl(magazineUrl);
      setTimeout(() => {
        setMessage("");
        downloadLinkRef.current?.click(); // Trigger download
      }, 3000);
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("An error occurred. Please try again.");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="w-full flex py-28 text-center md:text-left px-3 justify-center bg-[#9cf053]">
        <div className="grid lg:grid-cols-2 gap-8 max-w-[1240px] mx-auto">
          <div className="w-full">
            <h2 className="font-Archivo tracking-tighter uppercase text-4xl mb-4">
              Get Your Exclusive Free Magazine from Lewsr!
            </h2>
            <p className="mb-4">
              🌟 <strong>Join the Community!</strong> 🌟 Stay ahead of the curve
              with Lewsr's insights and stories. Sign up now to receive our
              exclusive eMagazine, packed with compelling content tailored just
              for you.
            </p>
          </div>
          <div className="font-Archivo tracking-tighter uppercase text-base">
            <form onSubmit={submitHandler}>
              <input
                type="email"
                value={input}
                onChange={inputHandler}
                className="p-4 text-gray-800 border-gray-200 bg-white w-full mb-4"
                placeholder="your@email.com"
                disabled={isSubmitting}
              />
              <button
                className="px-8 bg-black text-white font-bold p-4 uppercase w-full"
                disabled={isSubmitting}
              >
                Subscribe!
              </button>
            </form>
            {message && (
              <div className="mt-3 text-sm text-red-500">{message}</div>
            )}
            {downloadUrl && (
              <a
                href={downloadUrl}
                ref={downloadLinkRef}
                download
                style={{ display: "none" }}
              >
                Download
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
